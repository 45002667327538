<template>
  <div>
    <div
      v-if="isLoaded"
      class="mb-12"
    >
      <base-navbar-tabs
        :items="typeFilterOptions"
        v-model:active="typeFilterSelected"
      />

      <div
        class="mx-0 md:mx-3 space-y-4"
        v-if="isCategoriesVisible"
      >
        <div class="flex gap-4 items-center justify-end w-full">
          <label for="language">{{ $t('page.settings.profile-language.form.profile-language.label') }}</label>
          <multi-select
            v-model="selectedLanguage"
            name="language"
            :options="languages"
            mode="single"
            class="mx-0 w-64"
            :placeholder="$t('page.settings.profile-language.form.profile-language.label')"
          />
        </div>
      
        <swiper
          :key="typeFilterSelected"
          v-bind="swiperSettings"
          navigation
          @slideChange="changeCategory"
        >
          <swiper-slide
            class="p-2"
            v-for="item in categoriesFilterOptions" :key="item.code"
            v-slot="{ isActive }"
          >
            <free-content-category
              :category="typeFilterSelected"
              :isActive="isActive"
              :item="item"
            />
            <!-- <div
              class="card flex flex-col justify-start items-center h-40 p-4 select-none"
              :class="{ 'bg-blue-100': isActive }"
            >
              <img
                class="flex-none w-16 h-16 rounded-full object-cover shadow-lg mb-4"
                src="@/assets/profile/manyrobot-profile.svg"
              >
              <span class="text-sm text-center">{{ item.label }}</span>
            </div> -->
          </swiper-slide>
        </swiper>
      </div>

      <div
        class="flex-1 flex items-center relative"
        v-if="typeFilterSelected === 'quotes'"
      >
        <i class="fas fa-search text-gray-500 absolute ml-2"></i>
        <input
          v-model="textFilter"
          type="text"
          :placeholder="$t('page.templates.form.search.placeholder')"
          class="p-2 pl-8 rounded-tr-none rounded-br-none w-full"
        />
        <button
          class="btn btn-primary rounded-tl-none rounded-bl-none"
          @click.prevent="updateTextFilter"
        >
          {{ $t('page.templates.action.search') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref, watch } from 'vue'
import BaseNavbarTabs from '@/components/base/BaseNavbarTabs.vue'

// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'
import FreeContentCategory from './FreeContentCategory.vue'
import MultiSelect from '@vueform/multiselect'

// install Swiper modules
SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    BaseNavbarTabs,
    Swiper,
    SwiperSlide,
    FreeContentCategory,
    MultiSelect
  },

  props: {
    type: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: false
    },
    categories: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },

  emits : [
    'update:type',
    'update:category',
    'update:text',
    'update:language',
  ],

  setup (props, { emit }) {

    const swiperSettings = {
      loop: true,
      slidesPerView: 2,
      slideToClickedSlide: true,
      centeredSlides: true,
      grabCursor: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      breakpoints: {
        640: {
          slidesPerView: 4
        },
        1024: {
          slidesPerView: 5
        },
        1280: {
          slidesPerView: 6
        }
      }
    }

    const isLoaded = computed(() => {
      return props.categories.length > 0
    })

    const textFilter = ref(props.text)

    const selectedLanguage = ref<string>(props.language)

    const typeFilterSelected = ref(props.type)

    const typeFilterOptions = computed(() => [
      { code: 'template', label: 'page.templates.sentence.templates', icon: 'fas fa-palette' },
      { code: 'sazonal', label: 'page.templates.sentence.special_dates', icon: 'fas fa-calendar-check' },
      { code: 'quotes', label: 'page.templates.sentence.quotes', icon: 'fas fa-quote-right' }
    ])

    const categoriesFilterSelected = ref<string | null>(props.category ? props.category : null)

    const categoriesFilterOptions = computed(() => {
      const categories = props.categories as {
        code: string;
        label: string;
        type: string;
      }[]

      return categories.filter((category) => {
        return category.type === typeFilterSelected.value
      })
    })

    const isCategoriesVisible = computed(() => categoriesFilterOptions.value.length > 0)

    const changeCategory = (swiper: any) => {
      const index = swiper.realIndex
      if (unref(isCategoriesVisible)) {
        const options = unref(categoriesFilterOptions)
        if (options[index]) {
          categoriesFilterSelected.value = options[index].code
        }
      }
    }

    watch(categoriesFilterOptions, (options) => {
      if (options.length > 0) {
        categoriesFilterSelected.value = options[0].code
      } else {
        categoriesFilterSelected.value = null
      }
    })

    watch(typeFilterSelected, (type) => {
      emit('update:type', type)
    })

    watch(categoriesFilterSelected, (category) => {
      emit('update:category', category)
    })

    watch(selectedLanguage, (language) => {
      emit('update:language', language)
    })

    const updateTextFilter = () => {
      emit('update:text', textFilter.value)
    }

    return {
      categoriesFilterOptions,
      categoriesFilterSelected,
      textFilter,
      isCategoriesVisible,
      isLoaded,
      typeFilterOptions,
      typeFilterSelected,
      updateTextFilter,
      changeCategory,
      swiperSettings,
      selectedLanguage,
    }
  }
})
</script>

<style>
.swiper-button-next, .swiper-button-prev {
  display: none;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 9999px;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 2rem;
  height: 2rem;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  .swiper-button-next, .swiper-button-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}
</style>