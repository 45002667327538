
import { computed, defineComponent, ref, unref, watch } from 'vue'
import BaseNavbarTabs from '@/components/base/BaseNavbarTabs.vue'

// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'
import FreeContentCategory from './FreeContentCategory.vue'
import MultiSelect from '@vueform/multiselect'

// install Swiper modules
SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    BaseNavbarTabs,
    Swiper,
    SwiperSlide,
    FreeContentCategory,
    MultiSelect
  },

  props: {
    type: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: false
    },
    categories: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },

  emits : [
    'update:type',
    'update:category',
    'update:text',
    'update:language',
  ],

  setup (props, { emit }) {

    const swiperSettings = {
      loop: true,
      slidesPerView: 2,
      slideToClickedSlide: true,
      centeredSlides: true,
      grabCursor: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      breakpoints: {
        640: {
          slidesPerView: 4
        },
        1024: {
          slidesPerView: 5
        },
        1280: {
          slidesPerView: 6
        }
      }
    }

    const isLoaded = computed(() => {
      return props.categories.length > 0
    })

    const textFilter = ref(props.text)

    const selectedLanguage = ref<string>(props.language)

    const typeFilterSelected = ref(props.type)

    const typeFilterOptions = computed(() => [
      { code: 'template', label: 'page.templates.sentence.templates', icon: 'fas fa-palette' },
      { code: 'sazonal', label: 'page.templates.sentence.special_dates', icon: 'fas fa-calendar-check' },
      { code: 'quotes', label: 'page.templates.sentence.quotes', icon: 'fas fa-quote-right' }
    ])

    const categoriesFilterSelected = ref<string | null>(props.category ? props.category : null)

    const categoriesFilterOptions = computed(() => {
      const categories = props.categories as {
        code: string;
        label: string;
        type: string;
      }[]

      return categories.filter((category) => {
        return category.type === typeFilterSelected.value
      })
    })

    const isCategoriesVisible = computed(() => categoriesFilterOptions.value.length > 0)

    const changeCategory = (swiper: any) => {
      const index = swiper.realIndex
      if (unref(isCategoriesVisible)) {
        const options = unref(categoriesFilterOptions)
        if (options[index]) {
          categoriesFilterSelected.value = options[index].code
        }
      }
    }

    watch(categoriesFilterOptions, (options) => {
      if (options.length > 0) {
        categoriesFilterSelected.value = options[0].code
      } else {
        categoriesFilterSelected.value = null
      }
    })

    watch(typeFilterSelected, (type) => {
      emit('update:type', type)
    })

    watch(categoriesFilterSelected, (category) => {
      emit('update:category', category)
    })

    watch(selectedLanguage, (language) => {
      emit('update:language', language)
    })

    const updateTextFilter = () => {
      emit('update:text', textFilter.value)
    }

    return {
      categoriesFilterOptions,
      categoriesFilterSelected,
      textFilter,
      isCategoriesVisible,
      isLoaded,
      typeFilterOptions,
      typeFilterSelected,
      updateTextFilter,
      changeCategory,
      swiperSettings,
      selectedLanguage,
    }
  }
})
