<template>
  <dashboard-content>
    <template v-slot:actions>
      <quota-usage
        v-if="isQuotaLoaded && !lockAccess"
        :current="quota.current"
        :limit="quota.limit"
      />
    </template>

    <div>
      <filter-bar
        v-model:category="categoryFilter"
        v-model:text="textFilter"
        v-model:type="typeFilter"
        v-model:language="languageFilter"
        :languages="languages"
        :categories="categoriesList"
      />

      <the-posts
        :posts="posts"
        :loaded="isPostsLoaded"
        :lock="lockAccess"
        approvable
      />

      <div ref="listsEnd"></div>
      <div
        class="flex justify-center items-center space-x-2 w-full my-16"
        v-if="isLoading"
      >
        <i class="fas fa-spinner fa-spin"></i>
        <div>{{ $t('sentence.loading') }}</div>
      </div>
    </div>

    <template
      v-slot:notification
      v-if="lockAccess"
    >
      <offer-alert-wrapper>
        {{ alertMessage }}
      </offer-alert-wrapper>
      <offer-modal offer="freePostOffer" hide-button/>
    </template>
  </dashboard-content>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useFreeQuota } from '@/composables/profile/useQuota'
import { useFilters } from '@/api/groups/useFilters'
import { useFreePosts } from '@/api/publications/usePosts'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import FilterBar from '@/components/posts/readyToUse/FilterBar.vue'
import ThePosts from '@/components/posts/ThePosts.vue'
import OfferModal from '@/components/freeTrial/OfferModal.vue'
import OfferAlertWrapper from '@/components/freeTrial/OfferAlertWrapper.vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useIntersectionObserver } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    DashboardContent,
    QuotaUsage,
    FilterBar,
    ThePosts,
    OfferModal,
    OfferAlertWrapper
  },
  setup () {
    const listsEnd = ref(null)

    const { t } = useI18n()
    
    const {
      isQuotaLoaded,
      quota
    } = useFreeQuota()

    const {
      typeFilter,
      categoriesList,
      categoryFilter,
      textFilter,
      languages,
      languageFilter,
    } = useFilters()

    const {
      isPostsLoaded,
      isLoading,
      posts,
      scrollId,
      fetchPosts
    } = useFreePosts()

    const {
      isFreeTrial,
      isFreeTrialActive,
      hasActiveSubscription,
      lockAccess
    } = useProfile()

    const alertMessage = computed(() => {
      if (isFreeTrial.value && !isFreeTrialActive.value) {
        return t('state.finish_free_trial')
      } else if (!hasActiveSubscription.value) {
        return t('state.subscription_expired')  
      }
      return ''
    })

    useIntersectionObserver(listsEnd, ([{ isIntersecting }]) => {
      if (isIntersecting && scrollId.value) {
        fetchPosts({
          scrollId: scrollId.value
        })
      }
    })

    watch([categoryFilter, textFilter, languageFilter], ([category, text, language]) => {
      let params: any = {}

      if (language) {
        params.language = language
      }

      if (category) {
        params.group = category
      }

      if (text) {
        params.text = text
      }

      fetchPosts(params)
    })

    return {
      categoriesList,
      categoryFilter,
      isPostsLoaded,
      isLoading,
      isQuotaLoaded,
      posts,
      quota,
      textFilter,
      typeFilter,
      lockAccess,
      alertMessage,
      listsEnd,
      languages,
      languageFilter
    }
  }
})
</script>
