
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      required: true
    }
  },
  setup (props) {

    const image = computed(() => {
      try {
        const image = props.item.label
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '_')
        .toLowerCase()
      return require(`@/assets/img/free-content-icons/${props.category}/${image}.svg`)
      } catch (error) {
        return require('@/assets/profile/manyrobot-profile.svg')
      }
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/profile/manyrobot-profile.svg')
      }
    }
    
    return {
      image,
      addDefaultSrc
    }
  }
})
