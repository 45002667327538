import { onBeforeMount, ref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { availableLanguages } from '@/plugins/i18n'
import { useProfile } from '@/composables/profile/useProfile'

const fetchCategories = async () => {
  const groupsQuery = await firestore
    .collection('groups')
    .orderBy('name')
    .get()

  return groupsQuery.docs.map((groupSnap) => {
    const { name, type, relatedDate } = groupSnap.data()

    return {
      code: groupSnap.id,
      label: name,
      type,
      relatedDate
    }
  })
}

export const useFilters = () => {
  const { profile } = useProfile()

  const textFilter = ref('')

  const typeFilter = ref<'template' | 'sazonal' | 'quotes'>('template')

  const categoryFilter = ref<string | null>(null)

  const languageFilter = ref<string | null>(profile.value.language || 'pt-br')

  const categoriesList = ref<{
    code: string;
    label: string;
    type: string;
    relatedDate?: string;
  }[]>([])

  const languages = availableLanguages
    .filter((language) => language.context.includes('content'))
    .map((language) => ({
      value: language.value,
      label: language.caption,
    }))

  onBeforeMount(async () => {
    categoriesList.value = await fetchCategories()
  })

  return {
    categoriesList,
    categoryFilter,
    textFilter,
    typeFilter,
    languages,
    languageFilter,
  }
}
